/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox} from 'react-bootstrap';
import Select from 'react-select';

import {DashboardActions, DashboardConstants} from './dashboard-actions';
import {RELATED_TITLES_FILTER_VALUES, TITLE_FIELDS, TITLE_TYPE_FILTER_VALUES, TITLE_FIELDS_STATUS, TITLE_STATUS_SORT_ORDER, ACTIVE_STATUS} from './fields';
import {FormItem} from '../common/form/form';
import Panel from '../common/panel/panel';
import {WithStoreOnRoute} from '../common/store-on-route';
import {Debounce, GetAttr} from '../common/utils/utils';
import {LayoutActions} from '../layout/layout-actions';
import CompanySelect from '../lookup/company/company-select';
import CompanyStore from '../lookup/company/company-store';
import {MapToValues} from '../lookup/lookup-select-helpers';
import SessionStore from '../user/session/session-store';

const asArray = (m) => {
    return Object.keys(m).map(k => {
        return m[k];
    });
};

const ACTIVE_STATUS_VALUES = asArray(ACTIVE_STATUS);

let ActiveOptions = WithStoreOnRoute(class ActiveOptions extends Component {
    static get propTypes() {
        return {
            selectedOption: PropTypes.object
        };
    }

    static get defaultProps() {
        return {
            selectedOption: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setActiveFilter = this.setActiveFilter.bind(this);
    }

    setActiveFilter(status) {
        if (!status) {
            this.setRouteState('active', ACTIVE_STATUS.BOTH.apiName);
        } else {
            this.setRouteState('active', status.apiName);
        }
        this.clearRouteState('offset')
            .apply();
    }

    render() {
        return (
            <Panel removePadding={true} title="Status" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            components={{
                                DropdownIndicator: /* istanbul ignore next */() => null,
                                IndicatorSeparator: /* istanbul ignore next */() => null,
                            }}
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('apiName')}
                            isClearable={false}
                            isMulti={false}
                            name="active"
                            options={ACTIVE_STATUS_VALUES}
                            placeholder="Select..."
                            value={this.props.selectedOption}
                            onChange={this.setActiveFilter}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

let ReleaseDateOptions = WithStoreOnRoute(class ReleaseDateOptions extends Component {

    static get propTypes() {
        return {
            applyLastWeekFilter: PropTypes.bool,
            onChange: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            applyLastWeekFilter: false,
            onChange: undefined
        };
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
        this.setFormatDate = this.setFormatDate.bind(this);
    }

    handleChange(event) {
        const isChecked = event.target.checked;
        let endDate;
        let startDate;
        if (isChecked) {
            startDate = DashboardConstants.START_DATE;
            endDate = DashboardConstants.END_DATE;
        }
        this.setFormatDate('start-release-date', startDate);
        this.setFormatDate('end-release-date', endDate);
        DashboardActions.toggleLastWeekFilter(isChecked);
    }

    isChecked(value) {
        return value;
    }

    setFormatDate(attr, value) {
        this.setRouteState(attr, value)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
        return;
    }

    render() {
        let model = this.getRouteState();
        return (
            <Panel removePadding={true} title="Release Date" classTitle="box-title" collapsible>
                <div>
                    <Checkbox
                        key="last-week"
                        checked={this.isChecked(this.props.applyLastWeekFilter)}
                        onChange={this.handleChange.bind(this)}
                    >
                        {this.context.intl.messages['filter-options.release-date.last-week']}
                    </Checkbox>
                </div>
                <div className="box-body date-picker-filter">
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.from']}
                        attr="start-release-date"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        disabled={this.props.applyLastWeekFilter === true}
                        model={model}
                        onChange={this.props.onChange}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        label={this.context.intl.messages['filter-options.expire-date.to']}
                        attr="end-release-date"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        disabled={this.props.applyLastWeekFilter === true}
                        model={model}
                        onChange={this.props.onChange}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

let nonRemovables = [TITLE_FIELDS.NAME, TITLE_FIELDS.MPM];
let options = Object.keys(TITLE_FIELDS).filter(k => nonRemovables.indexOf(TITLE_FIELDS[k]) === -1).map((option) => TITLE_FIELDS[option]);

let optionsMap = Object.keys(TITLE_FIELDS).reduce((m, option) => {
    m[TITLE_FIELDS[option].id] = TITLE_FIELDS[option];
    return m;
}, {});

let HideFields = WithStoreOnRoute(class HideFields extends Component {
    static get propTypes() {
        return {
            hidden: PropTypes.array
        };
    }

    static get defaultProps() {
        return {
            hidden: []
        };
    }

    constructor(props) {
        super(props);

        this.onSelectChange = this.onSelectChange.bind(this);
    }

    onSelectChange(selected) {
        if (!selected || selected.length === 0) {
            this.clearRouteState('hidden');
        } else {
            selected = selected.map( s => s.id);
            this.setRouteState('hidden', selected);
        }
        this.setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
    }

    render() {
        return (
            <Panel removePadding={true} title="Hide Fields" classTitle="box-title" collapsible>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            components={{
                                DropdownIndicator: /* istanbul ignore next */() => null,
                                IndicatorSeparator: /* istanbul ignore next */() => null,
                            }}
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={false}
                            isMulti={true}
                            name="hidden"
                            onChange={this.onSelectChange}
                            options={options}
                            placeholder="Select..."
                            value={this.props.hidden}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

let RelatedTitles = WithStoreOnRoute(class RelatedTitles extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            location: []
        };
    }

    constructor(props) {
        super(props);

        this.onSelectChange = this.onSelectChange.bind(this);
        this.setRelatedToMPM = Debounce(this.setRelatedToMPM.bind(this), 200);
    }

    onSelectChange(selected) {
        let selectedRelated = '';
        if (selected) {
            selectedRelated = selected.id;
        }
        this.setRouteState('related-type', selectedRelated)
            .clearRouteState('offset')
            .apply();
    }

    setRelatedToMPM(attr, value) {
        this.setRouteState(attr, value)
            .clearRouteState('offset')
            .apply();
        return;
    }

    render() {
        let model = this.getRouteState();
        let selected = RELATED_TITLES_FILTER_VALUES.find((item) => {
            return item.id === this.props.location.query['related-type'];
        });

        return (
            <Panel removePadding={true} title="Related Titles" classTitle="box-title" collapsible>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <FormItem
                            label={this.context.intl.messages['filter-options.related-type.mpm-number']}
                            attr="related-to-mpm"
                            model={model}
                            placeholder={this.context.intl.messages['filter-options.related-type.mpm-number.placeholder']}
                            setter={this.setRelatedToMPM}
                            type="text"
                        />
                        <label>{this.context.intl.messages['filter-options.related-type.relation-type']}</label>
                        <Select
                            components={{
                                DropdownIndicator: /* istanbul ignore next */() => null,
                                IndicatorSeparator: /* istanbul ignore next */() => null,
                            }}
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={false}
                            isMulti={false}
                            name="hidden"
                            onChange={this.onSelectChange}
                            options={RELATED_TITLES_FILTER_VALUES}
                            placeholder="Select..."
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

let TitleType = WithStoreOnRoute(class TitleType extends Component {
    static get propTypes() {
        return {
            selected: PropTypes.number
        };
    }

    static get defaultProps() {
        return {
            selected: 0
        };
    }

    constructor(props) {
        super(props);

        this.onSelectChange = this.onSelectChange.bind(this);
    }

    onSelectChange(selected) {
        let s = '';
        if (selected) {
            s = selected.id;
        }
        this.setRouteState('title-type', s)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
    }

    render() {
        let selected = TITLE_TYPE_FILTER_VALUES.find((item) => {
            return item.id === this.props.selected;
        });

        return (
            <Panel removePadding={true} title="Title Type" classTitle="box-title" collapsible>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            components={{
                                DropdownIndicator: /* istanbul ignore next */() => null,
                                IndicatorSeparator: /* istanbul ignore next */() => null,
                            }}
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={false}
                            isMulti={false}
                            name="hidden"
                            onChange={this.onSelectChange}
                            options={TITLE_TYPE_FILTER_VALUES}
                            placeholder="Select..."
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

let ReleaseCompany = WithStoreOnRoute(class ReleaseCompany extends Component {
    static get propTypes() {
        return {
            selected: PropTypes.instanceOf(Immutable.Map)
        };
    }

    static get defaultProps() {
        return {
            selected: null
        };
    }

    constructor(props) {
        super(props);

        this.onSelectChange = this.onSelectChange.bind(this);
    }

    onSelectChange(selected) {
        let s = '';
        if (selected) {
            s = selected.id;
        }
        this.setRouteState('release-company-id', s)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
    }

    render() {
        return (
            <Panel removePadding={true} title="Release Company" classTitle="box-title" collapsible>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <CompanySelect
                            components={{
                                DropdownIndicator: /* istanbul ignore next */() => null,
                                IndicatorSeparator: /* istanbul ignore next */() => null,
                            }}
                            isClearable={false}
                            disabled={false}
                            multi={false}
                            name="releaseCompany"
                            placeholder="Select..."
                            onChange={this.onSelectChange}
                            value={this.props.selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

let SortOrder = WithStoreOnRoute(class SortOrder extends Component {

    static get propTypes() {
        return {
            orders: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    constructor(props) {
        super(props);

        this.onSelectChange = this.onSelectChange.bind(this);
    }

    onSelectChange(order, selected) {
        let s = '';
        if (selected) {
            s = selected.id;
        }
        this.setRouteState(`S${order}`, s)
            .clearRouteState('offset')
            .apply();
    }

    render() {
        let sortSelects = [0, 1, 2, 3].map( x => {
            let selected = TITLE_STATUS_SORT_ORDER.find((item) => {
                return item.id === this.props.orders.get(x);
            });
            return (
                <div className="form-group" key={x}>
                    <Select
                        components={{
                            DropdownIndicator: /* istanbul ignore next */() => null,
                            IndicatorSeparator: /* istanbul ignore next */() => null,
                        }}
                        getOptionLabel={GetAttr('name')}
                        getOptionValue={GetAttr('id')}
                        isClearable={false}
                        isMulti={false}
                        name="hidden"
                        onChange={this.onSelectChange.bind(this, x)}
                        options={TITLE_STATUS_SORT_ORDER}
                        placeholder="Select..."
                        value={selected}
                    />
                </div>
            );
        });
        return (
            <Panel removePadding={true} title="Order" classTitle="box-title" collapsible>
                <div data-style="display: block;" className="box-body">
                    {sortSelects}
                </div>
            </Panel>
        );
    }
});

let StatusFields = WithStoreOnRoute(class StatusFields extends Component {

    static get propTypes() {
        return {
            all: PropTypes.bool,
            defaultExpanded: PropTypes.bool,
            fields: PropTypes.array,
            onChange: PropTypes.func,
            values: PropTypes.array,
        };
    }

    static get defaultProps() {
        return {
            all: false,
            defaultExpanded: false,
            fields: [],
            onChange: () => void 0,
            values: [],
        };
    }

    constructor(props) {
        super(props);

        this.handleAll = this.handleAll.bind(this);
        this.handleField = this.handleField.bind(this);
        this.handleStatus = this.handleStatus.bind(this);
    }

    hasValues() {
        const routeState = this.getRouteState();
        const fieldNameTypes = routeState.get('field-name-type');
        const fieldStatusTypes = routeState.get('field-status-type');
        let result = false;

        if (!fieldNameTypes && !fieldStatusTypes) {
            result = true;
        }

        if (fieldNameTypes && fieldStatusTypes) {
            // Must be equal length arrays
            result = fieldNameTypes.size === fieldStatusTypes.size;
        }

        return result;
    }

    handleAll(value) {
        let v = 'OR';
        if (value) {
            v = 'AND';
        }
        this.setRouteState('status-operator', v)
            .clearRouteState('offset')
            .apply();

        if (this.hasValues()) {
            this.props.onChange();
        }
    }

    handleField(field) {
        if (!field || field.length === 0) {
            this.clearRouteState('field-name-type');
        } else {
            field = field.map( s => s.id);
            this.setRouteState('field-name-type', field);
        }
        this.clearRouteState('offset')
            .apply();

        if (this.hasValues()) {
            this.props.onChange();
        }
    }

    handleStatus(status) {
        if (!status || status.length === 0) {
            this.clearRouteState('field-status-type');
        } else {
            status = status.map( s => s.id);
            this.setRouteState('field-status-type', status);
        }
        this.clearRouteState('offset')
            .apply();

        if (this.hasValues()) {
            this.props.onChange();
        }
    }

    render() {
        const TITLE_FIELDS_ARRAY = asArray(TITLE_FIELDS).filter(statusField => statusField.apiName !== TITLE_FIELDS.ACTIVE.apiName);
        const TITLE_FIELDS_STATUS_ARRAY = asArray(TITLE_FIELDS_STATUS).filter(statusField => {
            return statusField.apiName !== TITLE_FIELDS_STATUS.ACTIVE.apiName &&
            statusField.apiName !== TITLE_FIELDS_STATUS.INACTIVE.apiName;
        });
        return (
            <Panel removePadding={true} title="Fields Status" classTitle="box-title" collapsible defaultExpanded={this.props.defaultExpanded}>
                <div className="form-group">
                    <p>Show:</p>
                    <p>
                        <label className="radio-inline">
                            <input
                                checked={!this.props.all}
                                type="radio"
                                name="all"
                                value="any"
                                id="any"
                                onChange={this.handleAll.bind(this, false)}/>
                            Any
                        </label>
                        <label className="radio-inline">
                            <input
                                checked={this.props.all}
                                type="radio"
                                name="all"
                                value="all"
                                id="all"
                                onChange={this.handleAll.bind(this, true)}/>
                            All
                        </label>
                    </p>
                    <p>of the following fields:</p>
                    <Select
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                        }}
                        getOptionLabel={GetAttr('name')}
                        getOptionValue={GetAttr('id')}
                        isClearable={false}
                        isMulti={true}
                        name="fieldNameType"
                        onChange={this.handleField}
                        options={TITLE_FIELDS_ARRAY}
                        placeholder="Select..."
                        value={this.props.fields}
                    />
                    <p>with the status:</p>
                    <Select
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                        }}
                        getOptionLabel={GetAttr('value')}
                        getOptionValue={GetAttr('id')}
                        isClearable={false}
                        isMulti={true}
                        name="fieldStatusType"
                        onChange={this.handleStatus}
                        options={TITLE_FIELDS_STATUS_ARRAY}
                        placeholder="Select..."
                        value={this.props.values}
                    />
                </div>
            </Panel>
        );
    }
});

class FilterOptions extends Component {

    static get propTypes() {
        return {
            applyLastWeekFilter: PropTypes.bool,
            location: PropTypes.object
        };
    }

    static get defaultProps() {
        return {
            applyLastWeekFilter: false,
            location: undefined
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            items: CompanyStore.getState().get('items')
        };
    }

    static getStores() {
        return [CompanyStore];
    }

    constructor(props) {
        super(props);

        this.arrayFromQuery = this.arrayFromQuery.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
        this.handleSaveQuery = this.handleSaveQuery.bind(this);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.applyLastWeekFilter !== this.props.applyLastWeekFilter ||
            nextProps.location !== this.props.location ||
            nextState.items !== this.state.items) {
            return true;
        }

        return false;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    arrayFromQuery(param, map, findBy = false) {
        let arr = [];
        let p = this.props.location.query[param];

        let getValue = (m, v) => {
            if (!findBy) {
                return m[v];
            }

            let r;
            Object.keys(m).some(k => {
                // Always convert to string, since "v" comes from the query string.
                if (m[k][findBy].toString() === v) {
                    r = m[k];
                }
            });

            return r;
        };

        if (p) {
            if (!Array.isArray(p)) {
                arr.push(getValue(map, p));
            } else {
                arr = p.map(v => getValue(map, v));
            }
        }
        return arr;
    }

    handleClearFilters() {
        const id = SessionStore.getState().getIn(['authUser', 'id']);
        DashboardActions.clearSavedFilters(id);
        if (this.props.applyLastWeekFilter) {
            DashboardActions.toggleLastWeekFilter(false);
        }
        return;
    }

    handleSaveQuery() {
        const id = SessionStore.getState().getIn(['authUser', 'id']);
        DashboardActions.saveDashboardFilters(id, this.props.location.query, this.props.applyLastWeekFilter);
        return;
    }

    render() {
        let hidden = this.arrayFromQuery('hidden', optionsMap);
        let fields = this.arrayFromQuery('field-name-type', TITLE_FIELDS, 'id');
        let values = this.arrayFromQuery('field-status-type', TITLE_FIELDS_STATUS, 'id');
        let activeStatus = ACTIVE_STATUS.BOTH;
        if (this.arrayFromQuery('active', ACTIVE_STATUS, 'apiName').length) {
            activeStatus = this.arrayFromQuery('active', ACTIVE_STATUS, 'apiName')[0];
        }
        let sortOrder = Immutable.List([0, 1, 2, 3]).map(x => {
            return this.props.location.query[`S${x}`];
        });

        let titleType = parseInt(this.props.location.query['title-type'] || '', 10);
        let releaseCompanyId = parseInt(this.props.location.query['release-company-id'] || '', 10);
        let mapProductionCompanies = MapToValues('id', CompanyStore).bind(this);
        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">

                    <div className="tab-content">

                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}><i className="fas fa-chevron-right pull-right"></i>&nbsp;Close Options</button>
                        </p>

                        <ActiveOptions
                            location={this.props.location}
                            selectedOption={activeStatus}
                        />

                        <SortOrder
                            location={this.props.location}
                            orders={sortOrder}
                        />
                        <ReleaseDateOptions
                            applyLastWeekFilter={this.props.applyLastWeekFilter}
                            location={this.props.location}
                        />

                        <StatusFields
                            fields={fields}
                            location={this.props.location}
                            values={values}
                            all={this.props.location.query.operator === 'AND'}
                        />

                        <RelatedTitles
                            location={this.props.location}
                            selected={titleType}
                        />

                        <TitleType
                            location={this.props.location}
                            selected={titleType}
                        />

                        <ReleaseCompany
                            location={this.props.location}
                            selected={mapProductionCompanies(releaseCompanyId)}
                        />

                        <HideFields
                            location={this.props.location}
                            hidden={hidden}
                        />

                        <p style={{overflow:hidden}}>
                            <button className="btn btn-block btn-primary" onClick={this.handleSaveQuery}>
                                {this.context.intl.messages['dashboard.filters.save-as-default']}
                            </button>
                        </p>

                        <p style={{overflow:hidden}}>
                            <button className="btn btn-block btn-danger" onClick={this.handleClearFilters}>
                                {this.context.intl.messages['dashboard.filters.clear-default-filters']}
                            </button>
                        </p>
                    </div>

                </aside>

                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(Container.create(FilterOptions));

export {
    ActiveOptions,
    FilterOptions,
    HideFields,
    ReleaseDateOptions,
    StatusFields,
    SortOrder,
    TitleType,
    ReleaseCompany,
    RelatedTitles
};
