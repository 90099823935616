/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Dropdown, Modal} from 'react-bootstrap';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';
import {Link} from 'react-router';

import MultiTenancyHomescreen from './multi-tenancy-homescreen';
import SidebarToggleButton from './sidebar-toggle-button';
import APP from '../../package.json';
import Avatar from '../common/avatar';
import {CustomMenu, CustomToggle} from '../common/custom-dropdown/custom-dropdown';
import {SessionActions} from '../user/session/session-actions';
import SessionStore from '../user/session/session-store';

class Navigation extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.shape({
                pathname: PropTypes.string.isRequired
            }).isRequired,
            version: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            version: null
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get childContextTypes() {
        return {
            defaultHomescreen: PropTypes.string.isRequired,
            location: PropTypes.shape({
                pathname: PropTypes.string.isRequired
            }).isRequired,
            onToggleModal: PropTypes.func.isRequired
        };
    }

    static calculateState() {
        return {
            authUser: SessionStore.getState().get('authUser'),
            defaultHomescreen: SessionStore.getState().get('defaultHomescreen'),
            isMenuOpen: false,
            isTenancyModalOpen: false
        };
    }

    static getStores() {
        return [SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.closeMenu = this.closeMenu.bind(this);
        this.handleSaveHomepage = this.handleSaveHomepage.bind(this);
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
        this.handleToggleModal = this.handleToggleModal.bind(this);
    }

    getChildContext() {
        return {
            defaultHomescreen: this.state.defaultHomescreen,
            location: this.props.location,
            onToggleModal: this.handleToggleModal
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.authUser !== this.state.authUser) {
            return true;
        }

        return false;
    }

    closeMenu() {
        if (this.state.isMenuOpen) {
            document.getElementById('user-menu').click();
        }

        return;
    }

    handleLogout() {
        SessionActions.endSession('logout');
        return;
    }

    handleToggleMenu(isOpen) {
        this.setState({
            isMenuOpen: isOpen
        });
        return;
    }

    handleToggleModal() {
        this.setState((prevState) => ({
            isTenancyModalOpen: !prevState.isTenancyModalOpen
        }));
        return;
    }

    handleSaveHomepage() {
        this.handleToggleModal();
        SessionActions.setDefaultHomescreen(window.location.pathname + window.location.search);
    }

    render() {
        let lastLogin = SessionStore.getState().get('lastLoginTimestamp');
        const lastLoginDate = Moment(lastLogin);
        if (lastLoginDate.isValid()) {
            lastLogin = lastLoginDate.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(this.context.intl.messages['datetime-full-ampm-tz']);
        }

        const firstName = this.state.authUser.get('name');
        const lastName = this.state.authUser.get('lastName');
        const avatar = <Avatar name={`${firstName} ${lastName}`} />;

        return (
            <nav className="navbar navbar-static-top" role="navigation">
                <SidebarToggleButton/>
                <MultiTenancyHomescreen/>
                <Modal show={this.state.isTenancyModalOpen} onHide={this.handleToggleModal}>
                    <Modal.Header className="bg-gray" closeButton>
                        <Modal.Title className="text-center" >{this.context.intl.messages['multi-tenancy.modal.title']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.context.intl.messages['multi-tenancy.modal.body']}</p>
                        <p style={{wordWrap: 'break-word'}}>
                            <strong>
                                {window.location.pathname}{window.location.search}
                            </strong>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-default-outline pull-left" onClick={this.handleToggleModal}>
                            <i className="fas fa-ban" /> {this.context.intl.messages['common.cancel']}</Button>
                        <Button className="btn btn-primary-outline" onClick={this.handleSaveHomepage}>
                            <i className="fa fa-check" /> {this.context.intl.messages['common.confirm']}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="navbar-custom-menu">
                    <ul className="nav navbar-nav">
                        <li className="user user-menu">
                            <RootCloseWrapper onRootClose={this.closeMenu}>
                                <Dropdown id="user-menu" pullRight onToggle={this.handleToggleMenu}>
                                    <CustomToggle bsRole="toggle" id="user-menu">
                                        {avatar}&ensp;&nbsp;<span className="hidden-xs">{firstName}&nbsp;{lastName}</span>
                                    </CustomToggle>
                                    <CustomMenu bsRole="menu">
                                        <div className="user-body">
                                            <Link onClick={this.closeMenu} to={`/accounts/${this.state.authUser.get('id')}`} style={{padding: '3px', marginBottom: 5, color: '#444'}} className="btn btn-block btn-default-outline">
                                                <i className="fas fa-user"></i>{this.context.intl.messages['navigation.profile']}
                                            </Link>

                                            <a style={{padding: '3px', marginBottom: '5px', color: '#444'}} className="btn btn-block btn-default-outline" onClick={this.handleLogout}>
                                                <i className="fas fa-sign-out-alt"></i> {this.context.intl.messages['navigation.sign-out']}
                                            </a>
                                        </div>

                                        <div className="user-footer">
                                            <div className="text-center" style={{cursor: 'default'}}>{this.context.intl.messages['navigation.last-login']} {lastLogin}</div>
                                            <hr style={{marginTop: 5, marginBottom: 5}}/>

                                            <div className="text-center">
                                                <small>Copyright &copy; 2025 Warner Bros. All rights reserved. Ver {this.props.version ?? APP.version}</small>
                                            </div>
                                        </div>
                                    </CustomMenu>
                                </Dropdown>
                            </RootCloseWrapper>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default Container.create(Navigation);
export {
    Navigation as Navigation_BASE
};
