/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import Pagination from '~/src/common/table/pagination';

import 'react-table/react-table.css';

export default class Table extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            displayLinks: PropTypes.bool,
            stations: PropTypes.instanceOf(Immutable.List).isRequired,
            onPageChange: PropTypes.func.isRequired,
            totalPages: PropTypes.number.isRequired,
        };
    }

    static get defaultProps() {
        return {
            displayLinks: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            id: Math.ceil(Math.random() * 100000),
            sortBy: '',
            sortDir: '',
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.getSortByClass = this.getSortByClass.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    /* istanbul ignore next */
    getSortByClass(column) {
        let r = 'sorting';
        if (column === this.state.sortBy) {
            r = `sorting_${this.state.sortDir}`;
        }
        return r;
    }

    handleSort(column) {
        let newSortDir = 'asc';
        if (this.state.sortBy === column && this.state.sortDir === 'asc') {
            newSortDir = 'desc';
        }
        this.setState({
            sortBy: column,
            sortDir: newSortDir
        });
    }


    render() {
        const getSortClass = this.getSortByClass;
        const handleSort = this.handleSort;
        /* istanbul ignore next */
        let columns = [{
            id: 'name',
            maxWidth: 365,
            accessor: c => c.get('name'),
            Header: <strong className={getSortClass('name')}>{this.context.intl.messages['station-groups.browse.station-name']}</strong>,
            Cell: (c) => {
                let stationName = c.original.get('name');
                if (this.props.displayLinks) {
                    stationName = <Link to={`/station-groups/${c.original.get('id')}`}>{c.original.get('name')}</Link>;
                }
                return (<div>{stationName}</div>);
            }
        }, {
            id: 'status',
            maxWidth: 100,
            accessor: c => c.get('status'),
            Header: <strong className={getSortClass('status')}>{this.context.intl.messages['station-groups.browse.status']}</strong>,
            Cell: (c) => {
                let active = <span><i className="fas fa-check-circle text-green" /> <span className="hidden-xs hidden-sm"> Active</span></span>;
                if (!c.original.get('isActive')) {
                    active = <span><i className="fas fa-minus-circle text-red" /> <span className="hidden-xs hidden-sm"> Inactive</span></span>;
                }
                return (<div>{active}</div>);
            }
        }, {
            id: 'users',
            maxWidth: 90,
            accessor: c => c.get('numberOfItemsInGroup'),
            Header: <strong className={getSortClass('users')}>{this.context.intl.messages['station-groups.browse.users']}</strong>,
            Cell: (c) => {
                return (<div>{c.original.get('numberOfItemsInGroup') || ' - '}</div>);
            }
        },
        {
            id: 'description',
            accessor: c => c.get('description'),
            Header: <strong className={getSortClass('description')}>{this.context.intl.messages['station-groups.browse.description']}</strong>,
            Cell: (c) => {
                return (<div>{c.original.get('description')}</div>);
            }
        },
        {
            id: 'dateadded',
            maxWidth: 145,
            accessor: c => c.get('dateadded'),
            Header: <strong className={getSortClass('dateadded')}>{this.context.intl.messages['station-groups.browse.date-added']}</strong>,
            Cell: (c) => {
                let created = Moment(c.original.get('createdDate'));
                if (created.isValid()) {
                    created = created.format(this.context.intl.messages['date-format']);
                } else {
                    created = '-';
                }
                return (<div>{created}</div>);
            }
        },
        ];

        const pagination = <div className="row">
            <div className="col-sm-7 col-sm-offset-5">
                <Pagination
                    activePage={this.props.activePage}
                    onChange={this.props.onPageChange}
                    totalPages={this.props.totalPages}
                />
            </div>
        </div>;

        return (
            <div>
                <ReactTable
                    className="-striped table-bordered table-striped responsive stations-table"
                    columns={columns}
                    data={this.props.stations}
                    getNoDataProps= {/* istanbul ignore next */() => {
                        if (this.props.stations.size) {
                            return {style: {display: 'none'}};
                        }
                        return {};
                    }}
                    loading={false}
                    showPagination={false}
                    sortable={true}
                    resizable={false}
                    pageSize={this.props.stations.size}
                    getTheadThProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: /* istanbul ignore next */() => {
                                if (column.sortable !== false) {
                                    instance.sortColumn(column);
                                }
                                handleSort(column.id);
                            }
                        };
                    }}
                />
                {pagination}
            </div>
        );
    }
}
