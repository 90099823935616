import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import {FormItem} from '../../common/form/form';
import UpdateInfo from '../../common/update-info/update-info';
import Validations from '../../common/validations/validations';

export const duplicatedLookupFn = (allItems, attrName, value) => {
    let lookupItem = allItems.find(
        (item) => item.get(attrName).toLowerCase() === value.toLowerCase()
    );
    return lookupItem === undefined;
};

export default class AddEditModal extends React.Component {
    static propTypes = {
        attrName: PropTypes.string,
        item: PropTypes.instanceOf(Immutable.Map),
        items: PropTypes.instanceOf(Immutable.List),
        onHide: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        show: PropTypes.bool,
        title: PropTypes.string.isRequired,
    };

    static contextTypes = {
        intl: PropTypes.object.isRequired,
    };

    static defaultProps = {
        attrName: 'name',
        item: Immutable.Map(),
        items: Immutable.List(),
        show: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            item: Immutable.Map(),
        };

        this.updateAttr = this.updateAttr.bind(this);
        this.isValid = this.isValid.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }


    componentDidUpdate(prevProps) {
        if (prevProps.item !== this.props.item) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                item: this.props.item,
            });
        }
    }

    updateAttr(attr, value) {
        this.setState((prevState) => ({
            item: prevState.item.setIn([...attr.split('.')], value),
        }));
    }

    handleSave() {
        this.props.onHide();
        this.props.onSave(this.state.item);
    }

    isValid() {
    // check name
        let itemName = this.state.item.get(this.props.attrName);
        return (
            Validations.required.validate(itemName) &&
            Validations.max(50).validate(itemName) &&
            Validations.custom(
                /*istanbul ignore next*/() => this.context.intl.messages['lookups.create.error'],
                duplicatedLookupFn.bind(
                    duplicatedLookupFn,
                    this.props.items,
                    this.props.attrName
                )
            ).validate(itemName)
        );
    }

    render() {
        let title;
        let additionalInformation;

        if (this.props.item === Immutable.Map()) {
            title = this.context.intl.messages['common.add'];
        } else {
            title = this.context.intl.messages['common.edit'];
            additionalInformation = (
                <UpdateInfo
                    updatedDate={this.props.item.get('updatedDate')}
                    updatedBy={this.props.item.get('updatedBy')}
                />
            );
        }
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">
                        {title + ' ' + this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormItem
                        type="string"
                        attr={this.props.attrName}
                        label={this.props.title + ' ' + this.context.intl.messages['common.name']}
                        model={this.state.item}
                        required
                        showErrorMessage={true}
                        setter={this.updateAttr}
                        validations={[
                            Validations.max(50),
                            Validations.required,
                            Validations.custom(
                                /*istanbul ignore next*/() => this.context.intl.messages['lookups.create.error'],
                                duplicatedLookupFn.bind(
                                    duplicatedLookupFn,
                                    this.props.items,
                                    this.props.attrName
                                )
                            ),
                        ]}
                    />
                    {additionalInformation}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="default"
                        className="pull-left"
                        onClick={this.props.onHide}
                    >
                        {this.context.intl.messages['common.cancel']}
                    </Button>
                    <Button
                        bsStyle="primary"
                        disabled={!this.isValid()}
                        className="bg-wb-blue"
                        onClick={this.handleSave}
                    >
                        {this.context.intl.messages['common.save']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
