/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'querystring-es3';
import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';

import Summary from './summary';
import TargetTypeSelect from './target-type';
import RouteValidator from '../../common/custom-validator/route/route';
import DocumentTitle from '../../common/document-title';
import {FormRow, FormItem} from '../../common/form/form';
import ListUsers from '../../common/list-users/list-users';
import {Tab, Tabs} from '../../common/routing-tab/routing-tab';
import {History} from '../../common/side-notes/side-notes';
import SlidingPanel from '../../common/sliding-panel/sliding-panel';
import {SlidingPanelActions} from '../../common/sliding-panel/sliding-panel-actions';
import {AlertsWarnings} from '../../common/validations/alerts-warnings';
import ActionsMenu from '../../layout/actions-menu/actions-menu';
import StickyHeader from '../../layout/sticky-header/sticky-header';
import Preloader from '../../preloader';
import PreloaderStore from '../../preloader/preloader-store';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import SessionStore from '../../user/session/session-store';
import {TargetTypeActions} from '../create/target-type-actions';
import {PushNotificationActions} from '../push-notification-actions';
import PushNotificationStore from '../push-notification-store';


class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            /**
             * Validate custom props inside the route object.
             */
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            offset: PushNotificationStore.getState().getIn(['users', 'offset']),
            originalPushNotification: PushNotificationStore.getState().get('originalPushNotification'),
            partners: PushNotificationStore.getState().get('partners'),
            pushNotification: PushNotificationStore.getState().get('pushNotification'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            showSendModal: PushNotificationStore.getState().get('showSendModal'),
            size: PushNotificationStore.getState().getIn(['users', 'size']),
            users: PushNotificationStore.getState().get('users')
        };
    }

    static getStores() {
        return [PushNotificationStore, PreloaderStore];
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.PUSH_NOTIFICATIONS.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.PUSH_NOTIFICATIONS.EDIT)
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab: 0,
            showSendButton: false
        }, this.constructor.calculateState());

        this.handleCancel = this.handleCancel.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleLoadUsers = this.handleLoadUsers.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSendNotification = this.handleSendNotification.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleUsersPageChange = this.handleUsersPageChange.bind(this);
        this.isDirty = this.isDirty.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.route.mode !== nextProps.route.mode ||
            this.props.params.id !== nextProps.params.id
        ) {
            this.init(nextProps);
        }
    }

    componentWillUpdate(nextProps) {
        if (this.props.route.mode === 'edit' &&
        this.props.location !== nextProps.location) {
            PushNotificationActions.getUsers(
                this.props.params.id,
                this.state.users.get('filters').toJS(),
                nextProps.location.query.offset,
                nextProps.location.query.size
            );
        }
        return;
    }

    handleCancel() {
        RouterActions.redirect('/push-notifications', true);
    }

    handleCancelAddSlidingPanel() {
        SlidingPanelActions.hide('add-target');
        TargetTypeActions.clear();
    }

    handleHideSendModal() {
        PushNotificationActions.hideSendModal();
    }

    handleLoadPage(pageNumber) {
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'offset': pageNumber*this.state.size,
                'size': this.state.size
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);

        return;
    }

    handleLoadUsers() {
        PushNotificationActions.getUsers(
            this.props.params.id,
            this.state.users.get('filters').toJS(),
            this.props.location.query.offset,
            this.props.location.query.size
        );
        return;
    }

    handleSave(event) {
        event.preventDefault();
        PushNotificationActions.savePushNotification(
            this.props.params.id,
            PushNotificationStore.getState().get('pushNotification'),
            PushNotificationStore.getState().get('pushNotificationAsset'),
            PushNotificationStore.getState().get('pushNotificationTitle'));
    }

    handleSearchChange(term) {
        PushNotificationActions.getUsers(
            this.props.params.id,
            {name: term},
            0,
            this.state.users.get('size')
        );
        return;
    }

    handleSendNotification() {
        PushNotificationActions.sendNotification(this.props.params.id);
    }

    handleShowSendModal() {
        PushNotificationActions.showSendModal();
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setState({
            sortDirection: sortDirection,
            sortFieldName: sortFieldName
        });
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection,
                'keep-scroll': true
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    handleTargetClick() {
        SlidingPanelActions.show('add-target');
    }

    handleUsersPageChange(page) {
        // page x this.state.size = offset.
        let query = Object.assign(
            {},
            this.props.location.query,
            {
                'offset': page*this.state.size,
                'size': this.state.size
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);

        return;
    }

    init(props) {
        if (props.route.mode === 'edit') {
            PushNotificationActions.findById(props.params.id);
        } else {
            PushNotificationActions.clear();
            TargetTypeActions.clear();
        }
        PushNotificationActions.getPartners(0, 9999);
        RouterActions.registerRedirectCheck(() => {
            return this.isDirty();
        });
    }

    /* istanbul ignore next */
    isDirty() {
        if (this.props.route.mode === 'create') {
            return false;
        }

        let pushNotification = this.state.pushNotification;
        let originalPushNotification = this.state.originalPushNotification;
        return !pushNotification.equals(originalPushNotification);
    }

    render() {
        let actionsMenu;
        let mode = this.props.route.mode;
        let eventsAndNotes = null;
        let pageTitle = this.context.intl.messages[`push-notifications.${this.props.route.mode}.title`];
        let pageDescription = this.context.intl.messages['push-notifications.edit.description'];
        let disableForm = true;
        let validations = PushNotificationStore.getValidations();
        let historyTab = null;
        let usersTab = null;
        let baseRoute = `/push-notifications/${this.props.params.id}`;

        if (mode === 'create') {
            baseRoute = '/push-notifications/create';

            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['push-notifications.create.save']}
            >
                <Button
                    bsSize="large"
                    className="btn btn-primary-outline Mr(3px) Mb(3px) Mr(3px) Mb(3px)"
                    disabled={mode==='create'}
                    onClick={this.handleShowSendModal}
                >
                    <i className="fas fa-paper-plane"/>&nbsp;{this.context.intl.messages['push-notifications.send.button']}
                </Button>
            </ActionsMenu>;

            disableForm = false;
            pageDescription = this.context.intl.messages['push-notifications.create.description'];
        } else {
            // edit
            if (this.props.permissions.canEdit) {
                disableForm = false;
            }

            actionsMenu = <ActionsMenu
                canEdit={this.props.permissions.canEdit}
                canSave={!validations.length && this.props.permissions.canEdit}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
            >
                <Button
                    bsSize="large"
                    className="btn btn-primary-outline Mr(3px) Mb(3px)"
                    disabled={mode==='create'}
                    onClick={this.handleShowSendModal}
                >
                    <i className="fas fa-paper-plane"/>&nbsp;{this.context.intl.messages['push-notifications.send.button']}
                </Button>
            </ActionsMenu>;

            usersTab = <Tab route={`${baseRoute}/users`} title={this.context.intl.messages['common.tab-title.users']}>
                <ListUsers
                    activePage={Math.ceil(this.state.users.get('offset')/this.state.users.get('size'))}
                    columns={['name', 'emailAddress', 'device', 'platform']}
                    displayLinks={this.props.permissions.canReadUsers}
                    onLoadPage={this.handleLoadUsers}
                    onPageChange={this.handleUsersPageChange}
                    onSearchChange={this.handleSearchChange}
                    onSortChange={this.handleSortChange}
                    sortDirection={this.state.sortDirection}
                    sortFieldName={this.state.sortFieldName}
                    totalPages={Math.ceil(this.state.users.get('total')/this.state.users.get('size'))}
                    users={this.state.users.get('results')}
                />
            </Tab>;

            historyTab = <Tab disabled={true} route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.PUSHNOTIFICATIONS}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;
        }

        let summaryTab = (
            <Tab
                id="summary"
                route={baseRoute}
                title={this.context.intl.messages['push-notifications.edit.summary-tab-title']}
            >
                <Summary mode={mode} disabled={disableForm} handleTargetClick={this.handleTargetClick} partners={this.state.partners} />
            </Tab>
        );

        let entityName;

        if (this.state.pushNotification.get('name')) {
            entityName = this.state.pushNotification.get('name');
        }

        const sendDescription = this.state.pushNotification.get('sendDescription', '').toLowerCase();

        return (
            <div>
                <DocumentTitle
                    message={`document-titles.${mode}-push-notifications`} entityName={entityName}
                >
                    <div>
                        <StickyHeader>
                            <div className="col-md-6">
                                <h1>
                                    <i className={ClassNames('fas fa-bell')}></i>
                                    &nbsp;{pageTitle}&nbsp;
                                </h1>
                                <p className="padding-top-20">
                                    <em>{pageDescription}</em>
                                </p>
                            </div>
                            {actionsMenu}
                        </StickyHeader>
                        <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                            <section className="content" id="contentContainer">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <Tabs location={this.props.location}>
                                            {summaryTab}
                                            {usersTab}
                                            {historyTab}
                                        </Tabs>
                                    </div>
                                    <div className="col-lg-3">
                                        <AlertsWarnings
                                            title={this.context.intl.messages['common.alerts-warnings-title']}
                                            validations={validations}
                                        />
                                        {eventsAndNotes}
                                    </div>
                                </div>
                            </section>
                        </Preloader>
                    </div>
                </DocumentTitle>
                <SlidingPanel
                    id="add-target"
                    onCancel={this.handleCancelAddSlidingPanel}
                >
                    <div>
                        <TargetTypeSelect
                            location={this.props.location}
                            slidingPanelId="add-target"
                        />
                    </div>
                </SlidingPanel>
                <Modal show={this.state.showSendModal} onHide={this.handleHideSendModal}>
                    <Modal.Header className="bg-navy" closeButton>
                        <Modal.Title className="text-center">Send Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><i className="fas fa-exclamation-triangle"/>
                            <b> Note: </b>This message will be sent immediately and cannot be undone.<hr />
                            To complete this action, type Send in the box below.
                        </p>
                        <FormRow>
                            <FormItem attr="sendDescription"
                                label={this.context.intl.messages['push-notifications.edit.send-description']}
                                md={12}
                                model={this.state.pushNotification}
                                onlySpaces
                                setter={PushNotificationActions.updatePushNotification}
                                type="text" />
                        </FormRow>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" className="fa-pull-left" onClick={this.handleHideSendModal}>
                            {this.context.intl.messages['push-notifications.edit.send-modal.cancel.button']}
                        </Button>
                        <Button bsStyle="primary"
                            className="bg-navy"
                            disabled={sendDescription !== 'send'}
                            onClick={this.handleSendNotification}>
                            {this.context.intl.messages['push-notifications.edit.send-modal.send-push-notification.button']}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export {Create};
export default Container.create(Create);
