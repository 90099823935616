/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';

import {FormItem} from '../../common/form/form';
import Panel from '../../common/panel/panel';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {LayoutActions} from '../../layout/layout-actions';
import {BatchActions, BatchConstants} from '../batch-actions';
import BatchStore from '../batch-store';

import {GetAttr} from '~/src/common/utils/utils';

let options = [];
Object.keys(BatchConstants.STATUS).map((option) => (
    options.push(BatchConstants.STATUS[option])
));

let StatusOptions = WithStoreOnRoute(class StatusOptions extends Component {
    static get propTypes() {
        return {
            onChange: PropTypes.func,
            selected: PropTypes.number
        };
    }

    static get defaultProps() {
        return {
            onChange: undefined,
            selected: undefined
        };
    }

    render() {
        let selected = parseInt(this.props.selected);
        if (selected >= 0) {
            selected = options.find((item) => {
                return item.id === selected;
            });
        }

        // This is hacky, but if we pass undefined to Select value sometimes the internal state won't get cleared
        if (selected === undefined) {
            selected = null;
        }

        return (
            <Panel title="Status Type" classTitle="box-title" collapsible defaultExpanded>
                <div data-style="display: block;" className="box-body">
                    <div className="form-group">
                        <Select
                            classNamePrefix="dropdown"
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={true}
                            isMulti={false}
                            name="status"
                            onChange={this.props.onChange}
                            options={options}
                            placeholder="Select..."
                            value={selected}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
});

let CreateDateOptions = WithStoreOnRoute(class CreateDateOptions extends Component {
    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
            onChange: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined,
            onChange: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }

        this.props.onChange(attr, value);
    }

    render() {
        let model;

        if (this.props.location) {
            model = this.getRouteState();
        } else {
            model = this.props.filters;
        }

        return (
            <Panel title={this.context.intl.messages['filter-options.created-date']} classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body date-picker-filter">
                    <FormItem
                        label={this.context.intl.messages['filter-options.date.from']}
                        attr="start-date-created"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        label={this.context.intl.messages['filter-options.date.to']}
                        attr="end-date-created"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

let ExecutionDateOptions = WithStoreOnRoute(class ExecutionDateOptions extends Component {
    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
            onChange: PropTypes.func
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined,
            onChange: undefined
        };
    }

    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        if (this.props.location) {
            this.setRouteState(attr, value)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }

        this.props.onChange(attr, value);
    }

    render() {
        let model;

        if (this.props.location) {
            model = this.getRouteState();
        } else {
            model = this.props.filters;
        }

        return (
            <Panel title={this.context.intl.messages['filter-options.execution-date']} classTitle="box-title" collapsible defaultExpanded>
                <div className="box-body date-picker-filter">
                    <FormItem
                        label={this.context.intl.messages['filter-options.date.from']}
                        attr="start-execution-date"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        label={this.context.intl.messages['filter-options.date.to']}
                        attr="end-execution-date"
                        datepicker={{popoverTargetOffset: '10px -36px', showYearDropdown: true}}
                        model={model}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

class FilterOptions extends Component {

    static get propTypes() {
        return {
            filters: PropTypes.instanceOf(Immutable.Map),
            location: PropTypes.object,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            filters: undefined,
            location: undefined
        };
    }

    constructor(props) {
        super(props);

        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.filters !== this.props.filters ||
            nextProps.location !== this.props.location) {
            return true;
        }

        return false;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    onChange(attr, value) {
        BatchActions.setFilter(attr, value);
        BatchActions.setFilter('operator', 'AND');
        BatchActions.get(
            BatchStore.getState().get('filters'),
            0,
            20
        );
    }

    onSelectChange(selected) {
        let selectedId;

        if (selected) {
            selectedId = `${selected.id}`;
        } else {
            selectedId = undefined;
        }

        if (this.props.location) {
            this.setRouteState('status', selectedId)
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
            return;
        }
        BatchActions.setFilter('status', parseInt(selectedId));
        BatchActions.setFilter('operator', 'AND');
        BatchActions.get(
            BatchStore.getState().get('filters'),
            0,
            20
        );
    }

    render() {
        let status;
        if (this.props.location) {
            status = this.props.location.query.status;
        } else {
            if (BatchStore.getState().getIn(['filters', 'status'])) {
                status = BatchActions.getState().get('filters').get('status').id;
            } else {
                status = -1;
            }
        }
        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">
                    <div className="tab-content">
                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}>
                                <i className="fas fa-chevron-right pull-right"></i>&nbsp;{this.context.intl.messages['filter-options.close-options.button']}
                            </button>
                        </p>
                        <ExecutionDateOptions
                            filters={this.props.filters}
                            location={this.props.location}
                            onChange={this.onChange}
                        />
                        <CreateDateOptions
                            filters={this.props.filters}
                            location={this.props.location}
                            onChange={this.onChange}
                        />
                        <StatusOptions
                            selected={status}
                            onChange={this.onSelectChange}
                        />
                    </div>
                </aside>
                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(FilterOptions);

export {
    CreateDateOptions,
    ExecutionDateOptions,
    StatusOptions
};
