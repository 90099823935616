/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {SlidingPanelActions} from '../../common/sliding-panel/sliding-panel-actions';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import LayoutStore from '../../layout/layout-store';
import ListTitles from '../../titles/browse/list-titles';
import FilterOptions from '../../titles/filter-options/filter-options';
import {TitleActions} from '../../titles/title-actions';
import TitleStore from '../../titles/title-store';
import {PushNotificationActions} from '../push-notification-actions';

class TargetTitle extends Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            location: PropTypes.object.isRequired,
            slidingPanelId: PropTypes.string.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const ts = TitleStore.getState();
        return {
            titles: ts.get('titles'),
            size: ts.get('size'),
            total: ts.get('total'),
            offset: ts.get('offset'),
            sortDirection: ts.get('sortDirection'),
            sortFieldName: ts.get('sortFieldName'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel')
        };
    }

    static getStores() {
        return [TitleStore, LayoutStore];
    }

    static get defaultProps() {
        return {
            disabled: false
        };
    }

    constructor(props) {
        super(props);

        this.getTitle = this.getTitle.bind(this);
        this.handleAddTitle = this.handleAddTitle.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        TitleActions.clear();
        TitleActions.get(Immutable.Map({}), 0, 20);
        return;
    }

    shouldComponentUpdate(nextProps, nextState /*, nextContext*/) {
        return nextState.titles !== this.state.titles ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel;
    }

    componentWillUnmount() {
        this.emptyRouteState();
    }

    getTitle(titleId) {
        let title;
        this.state.titles.map(a => {
            if (a.toJS().id.toString() === titleId) {
                title = a;
            }
        });
        return title;
    }

    handleAddTitle(event) {
        this.emptyRouteState();
        if (~event.target.className.indexOf('add-title-panel')) {
            let titleId = event.target.getAttribute('data-title-id');
            PushNotificationActions.updatePushNotificationTitle(this.getTitle(titleId));
            SlidingPanelActions.hide(this.props.slidingPanelId);
        }

        return;
    }

    handlePageChange(pageNumber) {
        // pageNumber x this.state.size = offset.
        TitleActions.get(Immutable.Map(this.props.location.query), pageNumber * this.state.size, this.state.size);
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('title', term)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
        TitleActions.get(Immutable.Map(this.props.location.query));
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        TitleActions.setSort(sortFieldName, sortDirection);
        TitleActions.get(Immutable.Map(this.props.location.query), 0, this.state.size, this.state.sortFieldName, this.state.sortDirection);
        return;
    }

    render() {
        let titles = this.state.titles;
        let totalPages = this.state.total;
        let offset =this.state.offset;
        let size = this.state.size;
        let columns = ['mpmNumber', 'title', 'titleReleaseDate', 'categoryName',
            {
                name: 'actions',
                get: function(title, props, context) {
                    return `<button
                        class="btn btn-primary add-title-panel"
                        data-title-id="${title.get('id')}"><i class="fas fa-plus"></i>&nbsp;${context.intl.messages['push-notifications.title.add']}</button>`;
                },
                onClick: this.handleAddTitle
            }
        ];
        let filterOptions;
        if (this.state.showFiltersPanel) {
            filterOptions = <FilterOptions
                location={this.props.location}
            />;
        }

        return (
            <div>
                <h3>
                    <i className="far fa-newspaper"></i>{this.context.intl.messages['push-notifications.add-item.title.titles']}
                    <small>{this.context.intl.messages['push-notifications.add-item.title.desire-title']}</small>
                </h3>
                <ListTitles
                    activePage={Math.ceil(offset/size) || 0}
                    columns={columns}
                    filterButton
                    displayLinks={!this.props.disabled}
                    linkTarget="_blank"
                    location={this.props.location}
                    onPageChange={this.handlePageChange}
                    onSearchChange={this.handleSearchTerm}
                    onSortChange={this.handleSortChange}
                    sortDirection={this.state.sortDirection}
                    sortFieldName={this.state.sortFieldName}
                    titles={titles}
                    totalPages={totalPages}
                />
                {filterOptions}
            </div>
        );
    }
}

export default WithStoreOnRoute(Container.create(TargetTitle));
export {
    TargetTitle as TargetTitle_BASE
};
