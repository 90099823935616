/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup, Modal} from 'react-bootstrap';
import Select from 'react-select';

import Item from './item/item';
import ItemAsset from './item/item-asset';
import ItemTitle from './item/item-title';
import {TargetTypeActions} from './target-type-actions';
import TargetTypeStore from './target-type-store';
import {Alert} from '../../../common/notification/alert';
import {SlidingPanelActions} from '../../../common/sliding-panel/sliding-panel-actions';
import {PublishingListActions} from '../../publishing-list-actions';
import PublishingListStore from '../../publishing-list-store';

import {GetAttr} from '~/src/common/utils/utils';

class TargetType extends Component {
    static get propTypes() {
        return {
            currentLanguage: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            editSlidingPanelId: PropTypes.string.isRequired,
            isTargetChanged: PropTypes.bool.isRequired,
            location: PropTypes.object.isRequired,
            options: PropTypes.instanceOf(Immutable.List).isRequired,
            publishingListLocalized: PropTypes.instanceOf(Immutable.Map).isRequired,
            slidingPanelId: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            targetType: TargetTypeStore.getState().get('targetType'),
            item: PublishingListStore.getState().get('item')
        };
    }

    static getStores() {
        return [TargetTypeStore, PublishingListStore];
    }

    static get defaultProps() {
        return {
            disabled: false,
            options: TargetTypeStore.getTargetTypes()
        };
    }

    constructor(props) {
        super(props);

        this.renderPage = this.renderPage.bind(this);
    }

    handleSelectionChange(targetType) {
        TargetTypeActions.updateTargetType(targetType);
        return;
    }

    renderPage() {
        let retVal;
        let targetType = TargetTypeStore.getState().get('targetType');
        let targetTypeId = TargetTypeStore.getState().get('targetTypeId');
        switch (targetType) {
        case ('asset'):
            retVal = <ItemAsset
                editSlidingPanelId={this.props.editSlidingPanelId}
                disabled={this.props.disabled}
                isTargetChanged={this.props.isTargetChanged}
                targetTypeId={targetTypeId}
                slidingPanelId={this.props.slidingPanelId}/>;
            break;

        case ('title'):
            retVal = <ItemTitle
                disabled={this.props.disabled}
                editSlidingPanelId={this.props.editSlidingPanelId}
                isTargetChanged={this.props.isTargetChanged}
                location={this.props.location}
                targetTypeId={targetTypeId}
                slidingPanelId={this.props.slidingPanelId}/>;
            break;

        case ('item'):
            if (this.props.isTargetChanged) {
                PublishingListActions.updateListItemAttr('asset', null);
                PublishingListActions.updateListItemAttr('assetId', null);
                PublishingListActions.updateListItemAttr('targetType', targetTypeId);
                PublishingListActions.updateListItemAttr('targetUrl', null);
            }
            SlidingPanelActions.hide(this.props.slidingPanelId);
            PublishingListActions.show();

            retVal = <Modal dialogClassName="modal-900w" show={this.state.showModal} onEnter={this.showPreloader} onHide={this.onHide}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages['publishing-list.list-item.target.item.detail']}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Alert withHeader/>
                    <Item
                        currentLanguage={this.props.currentLanguage}
                        disabled={this.props.disabled}
                        isTargetChanged={this.props.isTargetChanged}
                        item={this.state.item}
                        publishingListLocalized={this.props.publishingListLocalized}
                        targetTypeId={targetTypeId}
                        slidingPanelId={this.props.slidingPanelId}/>
                </Modal.Body>
                <Modal.Footer />
            </Modal>;
            break;

        default:
            retVal =(
                <div>
                    <div className="col-md-6 col-md-offset-3">
                        <div className="form-group">
                            <ControlLabel>
                                <h3><i className="far fa-dot-circle"></i>&nbsp;{this.context.intl.messages['publishing-list.list-item.select.targetType']}</h3>
                            </ControlLabel>
                            <div>
                                <FormGroup>
                                    <Select
                                        getOptionValue={GetAttr('value')}
                                        isClearable={true}
                                        isMulti={false}
                                        disabled={this.props.disabled}
                                        name="targetTypeList"
                                        onChange={this.handleSelectionChange}
                                        options={this.props.options}
                                        placeholder={this.context.intl.messages['publishing-list.add-item.select.placeholder']}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>
            );
            break;
        }

        return retVal;
    }

    render() {
        let pageToRender = this.renderPage();
        return (
            <div>
                {pageToRender}
            </div>
        );
    }
}

export default Container.create(TargetType);
