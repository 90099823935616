/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import createReactClass from 'create-react-class';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, ControlLabel, FormGroup} from 'react-bootstrap';
import {Link} from 'react-router';
import Select from 'react-select';

import {GetAttr} from '~/src/common/utils/utils';

export default createReactClass({
    propTypes: {
        disabled: PropTypes.bool,
        handleRemove: PropTypes.func,
        handleSelect: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
        publishingLists: PropTypes.instanceOf(Immutable.List),
        selectedValue: PropTypes.instanceOf(Immutable.Map),
        title: PropTypes.string.isRequired,
    },

    contextTypes: {
        intl: PropTypes.object.isRequired
    },

    getDefaultProps: function() {
        return {
            disabled: false,
            handleRemove: () => void 0,
            publishingLists: Immutable.List(),
            selectedValue: Immutable.Map(),
        };
    },

    render: function() {
        let listItems;
        let placeholder = `${this.context.intl.messages['common.select']} ${this.props.title}`;
        let publishingList;
        if (this.props.selectedValue) {
            let itemsToShow;
            let link;
            let redirection = `/publishing-list/${this.props.selectedValue.get('publishListId')}`;
            publishingList = this.props.selectedValue.toJS().publishingList;

            if (publishingList && publishingList.items) {
                itemsToShow = publishingList.items.slice(0, 4);
                itemsToShow = itemsToShow.reduce((prev, next, i) => {
                    prev += next.name || next.description;
                    if (i < itemsToShow.length-1) {
                        prev+= ', ';
                    }
                    return prev;
                }, '');

                itemsToShow = itemsToShow.replace(/<\/?(div|h\d|i|p|strong)>/g, '');
                let more;


                if (publishingList.items.length > 4) {
                    more = `${this.context.intl.messages['homescreens.plus']} ${publishingList.items.length - 4} ${this.context.intl.messages['homescreens.more']}`;
                    link = <Link to={redirection}>{more}</Link>;
                }
            }
            listItems = (
                <div className="display-list-contents">
                    <ControlLabel>
                        Display Name:
                        <Link to={redirection}>{this.props.selectedValue.get('publishListDisplayName')}</Link>
                    </ControlLabel>
                    <br/>
                    <small>{itemsToShow}{link && ', '}{link}</small>
                </div>
            );

        } else {
            publishingList = '';
        }
        let removeMethod = (
            <em style={{fontWeight: 'normal'}}>- To hide this section, select &quote;Empty List&quote;</em>
        );
        if (this.props.handleRemove) {
            removeMethod = (
                <span style={{fontWeight:'normal'}} onClick={this.props.handleRemove}>
                    (<a href="#" className="remove-list">
                        <i className="fas fa-times-circle"></i> Remove
                    </a>)
                </span>
            );
        }

        return (
            <div
                className="row homepage-list homepage-list-legacy homepage-list-upfronts homepage-list-playlist"
                key={this.props.label}>
                <Col md={6}>
                    <FormGroup>
                        <ControlLabel>{this.props.title}&nbsp;
                            {removeMethod}
                        </ControlLabel>
                        <br/>
                        <Select
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            isClearable={true}
                            isMulti={false}
                            isDisabled={this.props.disabled}
                            name={this.props.label}
                            onChange={this.props.handleSelect}
                            options={this.props.publishingLists.toJS()}
                            placeholder={placeholder}
                            value={publishingList}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    {listItems}
                </Col>
            </div>
        );
    }
});
