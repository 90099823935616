/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import {Col} from 'react-bootstrap';

export default createReactClass({
    propTypes: {
        onEdit: PropTypes.func,
        onRemove: PropTypes.func,
        readOnly: PropTypes.bool,
        value: PropTypes.string.isRequired,
    },

    getDefaultProps: function() {
        return {
            onEdit: /*istanbul ignore next*/() => void 0,
            onRemove: /*istanbul ignore next*/() => void 0,
            readOnly: false,
        };
    },


    render: function() {
        let content = <Col md={3} sm={4}>
            {this.props.value}
        </Col>;
        if (!this.props.readOnly) {
            content = <Col md={3} sm={4} className="hover-options">
                <a href="#" onClick={this.props.onEdit}>{this.props.value}&nbsp;
                    <i className="fas fa-edit option-link"/>
                </a>&nbsp;
                <i className="fas fa-times-circle text-red option-link" onClick={this.props.onRemove}/>
            </Col>;

        }
        return content;
    }
});
